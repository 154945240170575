/**
 * Generated by graphql-ruby-client
 *
 */

/**
 * Map local operation names to persisted keys on the server
 * @return {Object}
 * @private
 */
const _aliases = {
  CountComments: '9a8a80c06e7de6b649a42176e834a737',
  IndexComments: '526c1160f34e5ebc4bfadc4c36a6c5e1',
  AddRoleToUser: '79df3ff624f59ecd0468e484ac261319',
  AddSupplier: '364cbbae84ca53080c2f94ae7a0cf7cc',
  ApproveApproval: '7ea6e4b45cd204c0b7e7bba84ac555b7',
  ArchiveRecord: 'cc1eccf6a6e7b1026d15806277e8b797',
  AttachFileToCustomFieldValue: '627454db1002b3e6b67e566abe61ed33',
  CancelInvoicePayment: 'b11a1bd0334c83cb79f435178c947a9d',
  CancelPurchaseRequestItem: '284bb4f914414f7aa8905d50ea2ddd1c',
  ChangeCardDeliveryPin: '40516b97f909e1e9717a1d8793dffb64',
  ChangeMobilePhone: '2835d353b8211287bde584583f78f195',
  CloseCardSubscription: 'b866008cbc13843f1dc206038f4ad711',
  ClosePurchaseOrder: '82a6537556db83fbf55d4fb37795f8bb',
  ConfirmEnable2fa: 'c0866945a5c6adada00210134e5f8c79',
  CreateApprovalDelegation: '49589deeb939ad007937d2fca87e241b',
  CreateApproverCustomField: '02309a55e05879d24b8a9399ddd2ea4e',
  CreateAttachmentCustomField: 'e7421669fafb32465ebc07db73409a44',
  CreateBankAccount: 'd5a4a77c94ebb947a41b31497c509ee8',
  CreateCategory: '60ade325a2925d285248cc30b8bedce8',
  CreateCategoryCustomField: '9b5531411c65640ee7f629b3eb3c6fb4',
  CreateCategorySet: '5c2c3b7997534452361637ebe81164b3',
  CreateCheckboxCustomField: 'af4d270483f82493b8231975e94dcd51',
  CreateComment: '3ec515e5dbbd55894e481e0028fe60b5',
  CreateCostCentre: 'c3f7cd91650b17dc078c2024049e49c0',
  CreateCostCentreCustomField: 'dcfe2b4f8a92f00bad38bd21a0ef2427',
  CreateDataFilter: '6bc29d4e4701e18c12a782f74e4a29c1',
  CreateDataPath: 'e6ecc54af6f87df82c55c0c6bcacab5f',
  CreateDataScopingRule: '04d6e861ec17df59ad9ebf2de9ebd663',
  CreateDataSource: '8edc814dec7d8f5b3a199fb40a68c614',
  CreateDataSourceItem: '0af8be081cc98641cc84e4c1e8e9a5f6',
  CreateDataView: '19f9cadb6d249b28262349a20b12f728',
  CreateDataViewField: 'ff65a3c6ae86aa6cc864b724477d6872',
  CreateDateCustomField: 'be61edc70fb0b6aa78aab3414365bdaa',
  CreateDocumentTransaction: '7a9945a31f51a28a95f991e54cd5190b',
  CreateDraftDocument: '77ab7b2d810ae62798e64fd2fcaa3555',
  CreateIntegerCustomField: 'fb09b73426e98a9e476ad52f0f68c2b8',
  CreateInvoicePayment: '39daf009702ae8df9fae8dc8101d004a',
  CreateIssuerIntegrationConsumer: 'bae399cbd3fe013b0801ed65ce41ba58',
  CreateIssuerIntegrationRule: '2a1648aba4b35889c736ad380e892849',
  CreateLocation: '2c1bd215d419b596af3c8a9be5c7a2db',
  CreateMoneyCustomField: '6fa326a90c9bdc65afa630c627d9230c',
  CreateOrganisationUnit: 'c5226593e1f4b7a1ee3e8f101f13b284',
  CreateOrganisationUnitCustomField: '44a5ab6eed67d85a2a40a3ff484334ff',
  CreatePurchaseRequestItem: 'c2d1b01b923d456815b29207662629ca',
  CreateReferenceAssociation: 'c4a476a4d41c4a7806555aef6c2bc80b',
  CreateReport: 'ea749966b9b08cca829369b1baa92791',
  CreateReportSchedule: 'd8462af75aa5d6c28104dc73330c747a',
  CreateSelectCustomField: '578fbb7c9c374105264cc5853ead308b',
  CreateSpendLimit: '580526f4bc4187c393e76fe8b9f52516',
  CreateSubscriptionRenewalRequest: 'b7c71099509510a2e1c806daa3457a79',
  CreateTextAreaCustomField: 'b0422b6cf23a4a79209d4d0b0d33e8c8',
  CreateTextCustomField: '97bfdf2e1edd8f9fa94b4063f2af0da6',
  CreateUrlCustomField: '7fa3bb2fad0356d10f70fc26cdb05b6b',
  CreateUser: '820f561381c479e98d1f0ce109d7e0db',
  CreateWorkflow: '7c522e881bb69f42e0fda710c5480de8',
  CreateWorkflowAction: '49c79f97c32d433b1c95b0af575e0a34',
  CreateWorkflowCondition: 'becb5fa375fd6532528ebdec90e6d24d',
  DeleteDocumentTransaction: '8ba4b2c3bba1db3d7c6e564a1afd9dfa',
  DeleteRecord: '2e9149acb1f6e582c5562c70b76e1c38',
  DeliverVirtualCardInApp: '8f6ba7213b315ca4e9dc61d275d8fbd2',
  DetachFileFromCustomFieldValue: '9b8370a7425373821ba4c472a5c17559',
  Disable2fa: 'e4f075f424126b06d963a5c84f51cbe8',
  DownloadAttachment: '99cd1b9c73cf8c25e8113e145c1a4098',
  DownloadPurchaseOrderDocuments: 'b65883d1dc58fffff3f7734c93efc931',
  DuplicateDataView: '6764b1dd015c863cd0d55c8419e44812',
  Enable2fa: 'd9baf7b279248cf75abe7004ca724a0b',
  GetImportFileMetadata: '3f83a51cc90bcf84772f7990e0a29cee',
  ImportCategories: '3ebe35c5e8314a8534c33ed6024b4336',
  ImportCostCentres: 'be8c6c94e4115b4f5fa968509870c662',
  ImportDataSourceItems: 'e8cfcf6ea8ff7e38176a5d7981d2b73b',
  InviteAllDraftUsers: 'a4a68d39fb0982d1ace784bf3ec86ac6',
  InviteDraftUsers: '303a356ad27e07c9c47d55cfa77fb4c8',
  InviteSupplier: '4b3acf7ffbc017d8e270d9ea96684d0c',
  LookupCompany: 'ec74ba4878fab6971e00ae766f402c19',
  MarkAllNotificationsAsRead: '26b947ad3bfe105e85da4b8106cc2701',
  MarkNotificationAsRead: '221a7895454f6d75dfa4eaa5d1740ae7',
  ProcessOnboardingCase: '59fd7548569deb7f0da65fe8e91ae31f',
  PublishWorkflow: 'bd5de606d824cba53fcc342a760f74d8',
  RefreshOnboardingContact: '528188a6db54060f83e5efa7127fb067',
  RejectApproval: 'ee1aee1af32a867685b0143568887acc',
  RemoveRoleFromUser: '9e0f0607e247e6030ca0ad268b4816d6',
  RemoveSupplier: '0ccbf54d3fa2a69e7c06582caf43b8af',
  RevokeVirtualCard: '3ff298ff753d67b847ecbef72ce8c32d',
  SnoozeRemindersForTransaction: 'c061eb9bb4f1a8b048892aba71c2b117',
  SubmitDocument: '19a84065d09d875502bc113fcebd0e15',
  SubmitPurchaseRequestItem: '1b0f3cb32a6651ec3ff84b1d6b1fbcc6',
  SubscribeToNotifications: 'f68485e2e708a99ffaad61073273d25f',
  UnarchiveRecord: 'ff5e9626e1ce6fe2ffd406d8c4d6bfb8',
  UnsubscribeFromNotifications: 'e84b5d83b7048407d7cee8a5c4162f87',
  UpdateAccountProfile: '2cb059ec272b61d166e1a169948340fa',
  UpdateAccountSettings: '16eba5613a60f50fd738c35544ffb221',
  UpdateApprovalDelegation: '5b9f87fccd20ffbd8c34258c61021a6d',
  UpdateApproverCustomField: 'd0e757f7fe975f39fc024fcc6230b85b',
  UpdateAttachmentCustomField: 'ee79b5e080fab394f6be391ea5019351',
  UpdateCardSubscription: 'e4ae96d4770760fdf542cec77a3a1b42',
  UpdateCategory: 'b56f3001bda91a1dc06a7fa9fedd6fb7',
  UpdateCategoryCustomField: 'f0c79b76b0584958180a965be92db351',
  UpdateCategorySet: '6254ae3d9013cb56c751d6ef22c3630a',
  UpdateCheckboxCustomField: 'e4124171259f7c15f647dfe667683dfd',
  UpdateCostCentre: 'f7cc6efd4c7c1535a7d89a43dae3e5e3',
  UpdateCostCentreCustomField: '84bece8ea1b470e1a3c975e8b9e04eef',
  UpdateDataFilter: '4838576e37834380440a1ad0a8f6e85a',
  UpdateDataPath: '76d0412c060f5c207925f094e3546025',
  UpdateDataScopingRule: '1d5230d694de1cb2e68c22e9c46e960c',
  UpdateDataSource: '4e1a04678c126dedb9ae80ac8b90893c',
  UpdateDataSourceItem: '9d9728176a1f863c52c293d59e1f7b7a',
  UpdateDataView: '8e1b8922cf3f16bfc94f8560af01da4e',
  UpdateDataViewField: '665fa6e3812f8925614cf0e5113aa70b',
  UpdateDateCustomField: 'e282d7852876bb582bcf6a2bb1ebf687',
  UpdateDocument: '77e10f7b8856f242f5c1a9235c7eb484',
  UpdateDocumentType: '0c45a6220a3d250842798b9ceb2e4483',
  UpdateFlowStepCoordinates: 'c18d4403dc12a746020790abc005373d',
  UpdateIntegerCustomField: '18f7ec45de833c0cdddf8cb045a84caa',
  UpdateIssuerIntegration: 'bd385b589a44ac6e6cfe52579fca969f',
  UpdateIssuerIntegrationConsumer: '19c3b2e17ede74473f72d159dea52e36',
  UpdateIssuerIntegrationRule: '5adeaa9c7fddd8931a01b2f2bf425e25',
  UpdateLocation: '4fae2b85d9a8222039ac0516cc554fe1',
  UpdateMoneyCustomField: '8fdf8b55aaea4a760434e133eff12cdc',
  UpdateOrganisationUnit: '21dc424cafc51c4be8f3ca584a0d0037',
  UpdateOrganisationUnitCustomField: 'b9bea46ec64dd718ab002d4c7dcbfe2c',
  UpdatePurchaseRequestItem: 'b7791bf6ce07ae05d3a0f8a7dbc253e8',
  UpdateRecordParent: '1d10ac91039c3d8b5b58218dd74417ae',
  UpdateRecordPosition: '116114a63d61b1b79d873356d97fe535',
  UpdateReportSchedule: '2b854286c4aa790b5a36a9349f22281f',
  UpdateSchedule: '9dcb4b7574cc323ce85ecf16a28f5ac5',
  UpdateSelectCustomField: 'f73e8243531a9ef16990d4cf51d58fa7',
  UpdateSpendLimit: 'e28c8e61051e492ee7251561ddb66fd1',
  UpdateTextAreaCustomField: 'c93c73f12b6b52c72f5bab3f991e4c93',
  UpdateTextCustomField: 'dd84685a9b8028ebbe73e12d69bc161b',
  UpdateUrlCustomField: '2012376af365cf673b37301cac1d66c9',
  UpdateUserNotificationSetting: '2fcf603e3da13474d655063b21b22396',
  UpdateUserProfile: '6b5d1db8cd291c27b8a3102fa5b95358',
  UpdateUserSettings: '0ea93f13a4e4a41c10225df14cf419d9',
  UpdateWorkflow: 'd59e186f6ed5ae2527cd9c02f18a1b56',
  UpdateWorkflowAction: 'c64cf4886c3d9190de8765813fd05a3c',
  UpdateWorkflowActionDescription: 'e0ff575bbafd33e8b2377e200edb2e72',
  UpdateWorkflowActionOutput: 'e96c2ad31a11a248e1da5979a94df1f8',
  UpdateWorkflowCondition: 'b88828d0ba8d46ab7500f83198b76b3f',
  UpdateWorkflowConditionDescription: '667b45e8203910d013db4fea171d29a1',
  UpdateWorkflowConditionOutput: 'aca386f294e6f3b1a87260537619fc61',
  VerifyMobilePhoneVerificationCode: '9b64cb51233d909eeca235177089bc97',
  ImportUsers: '62307059981e8c0e4c8640e303ebf004',
  CountActivities: '7747b5f9c2256194c881c935a961d8a7',
  CountApprovals: '01f70646763eb8e07f009ee2837c7ba1',
  CountCardAuthorisations: '45be6a8e695aa52de7984c11e83e6787',
  CountCardSubscriptionPurchaseOrders: '07bedeb49a4e9f66fba22f77045fb95e',
  CountCardSubscriptionPurchaseRequestItems: 'db5bd9f00fa03db75fa5f519cf1f0e4e',
  CountCardSubscriptionTransactions: 'a9063d9c11b082bba722d8e5c82caa76',
  CountCardSubscriptionVirtualCards: '5de96ee22c24b4182fcf90728061e9d5',
  CountCardSubscriptions: '10429b954483c6f7d043f93a4f4b0915',
  CountCostCentres: '07403ae47117c32159162da4985abb02',
  CountCustomFields: '48802afaa5a088d4200b15c350f4d6e8',
  CountDataScopingRules: '0dfb2f1730f5c70c00e05ae3e7c15df9',
  CountDataSourceItems: 'be82ae1ccf125a4ec6415c7141340b29',
  CountDataSources: '957764c25902d2ebbb2658bf82c34d34',
  CountDataViewFields: '54885ac6ee25044ab5c4667e9667a170',
  CountDataViewFilters: '5f24d2288ecb531465d238dd0e665565',
  CountDocuments: '7f060edb8de653af4b39da4a7e6f3c73',
  CountInboxIncomingEmails: 'cb168f755bcb08f4cf14b90cc62d4d4d',
  CountIndexTransactions: 'c02bbb1ad26ef7c397fcbaab5e8e0740',
  CountIndustryCodeSuppliers: 'dae58eb584977cc829c423515c85958c',
  CountIssuerIntegrationConsumers: '75f9c6859ca0dc52f940bc96c10aefe2',
  CountIssuerIntegrations: 'c17dc5f9420ad66b91799cf7a67b94ef',
  CountMerchants: '560b50a087077e4fac2ab8aeb7316df3',
  CountOrganisationUnitUsers: 'f5c6ecfebec8e8704edf1f3e69d15d62',
  CountOverviewApprovals: 'b6cdb15224f4a46b1812566b5c5ef043',
  CountOverviewCards: 'cdb434414435f6af4a3807d6476148ba',
  CountOverviewRequests: 'bc8064bc5878f6e1122ccaebde9f8965',
  CountOverviewTransactions: 'a3ecbf7fa857fcf8c4fd7f54b869c9b6',
  CountPurchaseRequestItemInvoicePayments: 'a2f734f6d5ac0efecf77ac2c41461389',
  CountPurchaseRequestItemTransactions: '03bd92f885468598eadfcdbf9124930e',
  CountPurchaseRequestItems: '54bacc1a8640d3ccc95628e4d96d24cf',
  CountReportScheduleSubscribers: '59cb109129d195696a80306a94c4163d',
  CountReports: '85e8c886c6fb8e5596594657e7477ed3',
  CountResourcePreviewTransactions: 'be600e2a916771a71986c8052706e578',
  CountSupplierAccountInvoicePayments: 'efb8e79a81a5336af557989728fa45b2',
  CountSupplierInvoicePayments: '12a817a74ba180b221bc7f3dbfecf4d9',
  CountSuppliers: 'bea859a245d1a07057f50c9f407b7d16',
  CountUserApprovals: '79284f10bc77bc757290bda82565777d',
  CountUserCustomFields: '43180c4e3dfda86096ab0b60136c28fc',
  CountUserRequests: '987fc98c08f075d15b0444cfbf3acd84',
  CountUsers: '3ba44be0b657b1d8f0f5ed04372f4383',
  CountUsersAwaitingInvitation: 'fe658aaefd76474834abca8cad370a05',
  CountVirtualCards: 'cf517be91471c0434b3743d4205cdb8f',
  CountWorkflowRuns: 'cc84a9794ed2433102d92b0b883ce3f3',
  CountWorkflowVersions: '3d4d62ca4ecf7edb3254167e8929b828',
  CountWorkflows: '69fc28a4478fa92700ffabe051c2ad56',
  IndexActivities: '72c017f5aa91a969c8d6a8bb4c4cdca1',
  IndexApprovalDelegationsFromYou: 'a04285b92644d6136d6dfa280a276ae3',
  IndexApprovalDelegationsToYou: '85ca3c31e56aa469698d338bc4d6b0ee',
  IndexApprovals: '97a80380e5b7f54a0a21732310cc1408',
  IndexAudits: '796ebd16b31300cd09191f400047ee79',
  IndexBankAccounts: '9c3627e2b01492e0e673e9676f3c7c4c',
  IndexCardAuthorisations: 'dc0e6a0d172103d277dbc7fea67d40f4',
  IndexCardSubscriptionMerchants: 'bdcc70f1fbb6b15ee14390d70950f948',
  IndexCardSubscriptionPurchaseRequestItems: '377e9d02ee4ef5e1579dffe1506b5e1e',
  IndexCardSubscriptionTransactions: '0e27ebf1c08b5002496b9b6972a1ca18',
  IndexCardSubscriptionVirtualCards: '8ab1dc3cba9a96e4586e87f75017f611',
  IndexCardSubscriptions: '7c907ba2cb2a57f606f9fd311fafd2ca',
  IndexCategories: '70bf01dd77605f18f84b509b2a8bab62',
  IndexCategorySets: '46e4d1a46884850ea81695932cba9429',
  IndexConditionReferenceValues: 'd2c93f91c92fb50a379870fde0f4b007',
  IndexCostCentres: '4344f7e1ae8109f05e3654204c769c3a',
  IndexCustomFieldPreviews: '4455329c58af213f2fd8963ddc60cb7c',
  IndexCustomFields: 'f30aa3a8fe6700f9086155e552198162',
  IndexDataFilters: 'd86864e915c4f031ec05bafcbca21e89',
  IndexDataScopingRules: '56d5784c9904dcd85114c0f24043c8cb',
  IndexDataSourceItems: '7af716a6ce3e56878c6607278f461f0a',
  IndexDataSources: '45cb4f6bc355fcc7e8a692e0b3bda099',
  IndexDataViewFields: '6dc9d10a7731389abbf5bd55aca33fa1',
  IndexDataViews: '0a6fd3387473add9753e5a777001c9e3',
  IndexDataViewsMetadata: '0f22f65079dae5fa406a29be594ecff0',
  IndexDocumentsPanelDocuments: '9d4954262033c200e088b3a310870fda',
  IndexDocumentsTabDocuments: '18b79644e094e06348e3feb98b288d39',
  IndexDraftRequestItems: 'f6186a92518b6b7781dbdf8f1b6c4fdf',
  IndexFlowStepResults: '9e52f9bfa10bcd572a945d57dc487294',
  IndexFormCustomFields: '0cee968e1d3f6d37ee3010533cd00701',
  IndexInboxIncomingEmails: 'c5c223b13d744b4f2d143152e0c82dbb',
  IndexIncomingEmailAttachments: '9defcbdbecf157dbb520e95214385326',
  IndexIssuerIntegrationConsumers: 'a732f006bd52a851f5a9621af764eb72',
  IndexIssuerIntegrationRules: '6051877dbbf9aab88b700b17299238ce',
  IndexIssuerIntegrations: 'edb3877da892f1f2978c30bcfe5f23ea',
  IndexLocations: '45707bade49b4f5cfabac9aeddb563e1',
  IndexMeNotifications: 'c44b81401c729b5645e9d663f271b6af',
  IndexMerchantCategoryCodes: '71b1d1660facef6c50efabdf6b2c5419',
  IndexMerchants: 'bb216e13bd426847f752150c61c65505',
  IndexOrganisationUnits: '28e680602bf2c884f56c47b8913d2525',
  IndexOverviewApprovals: '5972f04f3debaa18fde0511b680915f3',
  IndexOverviewCards: 'c2bdefdac63f7a2a4c9f34e182c91ae3',
  IndexOverviewRequests: '9edd051c6e56ddb1d9606162433fa8ba',
  IndexOverviewTransactions: '5d2be9de2fbef630ab5250bc89597b9a',
  IndexPurchaseRequestItemInvoicePayments: '17abf0a734618959abd923000083d9c6',
  IndexPurchaseRequestItemTransactions: '07542a2e64bd8ab2581525aa218f8af7',
  IndexPurchaseRequestItemTransactionsOverview: '503ee8753f15ebfd1fc23b26877dd46e',
  IndexPurchaseRequestItems: '6313c40ec10318e21ca7758132101857',
  IndexReceiptMissingPanelDocuments: '9621ec2eae8a050635b5495e78ea23e5',
  IndexReportScheduleSubscriberNames: '730b6be6cb7de29dbf98214785bdfc28',
  IndexReportScheduleSubscriberResources: '138a3d651b0d66c85bddb12391ce2b69',
  IndexReportSchedules: '5ec6256069a4971f0ea15d70d6776786',
  IndexReports: '0a592bfa8f2acd1ea8812b9efca465bd',
  IndexRequestFormSettingsDataSources: '04e2b73496162d42611d4a1443dcca08',
  IndexResourcePreviewTransactions: '4786ec758bc1a27d82c637e81058f71b',
  IndexSpendLimits: '083d9b19737780e8371f1f828fea0730',
  IndexSubscriptionPurchaseOrders: '822af6ef903e8f2c6ec7a42660303a01',
  IndexSupplierAccountInvoicePaymentResources: 'a83e4fc6c0f4a770b92d4174142b1691',
  IndexSupplierInvoicePayments: '7cd2a45283c4ae5cd7ed919b3ed8c23e',
  IndexSupplierOnboardingUsers: 'eecf66d8f7fc1de1ab352254ab023368',
  IndexSuppliers: '28722e7500c867bc97ad5b9125e3f8cd',
  IndexTransactions: '56c4a0e1f200534e1ac687b22141d92b',
  IndexUsers: '2b49601efc832087a8a12e2d1a4bc657',
  IndexVirtualCards: 'b3555f14a0725de035737e46ba4660ad',
  IndexWorkflowActions: 'd2292c6bc7a62c528a2b3dda5dc97167',
  IndexWorkflowConditions: '87ff28e4674effb41bb7f5169aaf9520',
  IndexWorkflowMasterVersions: 'e5fcc572956198734f6f09aedff4778c',
  IndexWorkflowRuns: '819eaaab912a3e6a44d31f91ade319d3',
  IndexWorkflowVersions: '5238b2e712c5bc492109418abfab5201',
  IndexWorkflows: 'f4612fe11dec8065b43d06dc4454acb2',
  Me: '9bf7faa7803c1e2707ef1913f621fd32',
  MyAccount: '42f6e557f9110793f41fd5e21a779ae0',
  MyAccountSettings: 'f8949ae1f74af0e124faa8eedf4b34f1',
  MySettings: '3005765be440489eeb25155dc80ce03f',
  PickBankAccountsViewerPermissions: '1c23a28d00b39a7a6422539524bd4aec',
  PickCardAuthorisationsViewerPermissions: '6ffa3aa60b5cc53374c9f820af873902',
  PickChildrenCount: 'ced2deff02e96b9dbb34a48ab3c32c6f',
  PickFirstStep: 'b702394bfb6bb0aed59deab5ab00e750',
  PickFlowStep: '9b5b9542ce64c68f3b56f26ccde8afd6',
  PickIntercomUserHash: 'b50987861f34a61ae291e22107c51e21',
  PickPurchaseRequestItemMerchant: '2aac7f4687fe5d2d76ea982823248c4f',
  PickRejectComment: 'bbb269521894ee31c2b8d604dc39c7b7',
  PickReportConnectionViewerPermissions: '51fdd6c16bd8643dc77e78020895997d',
  PickSupplierOnboardingContact: '3147f4319c010adf30f9cea34b556638',
  PickTitle: 'c46f65602f8d207d2b29b1d79563834b',
  PickTransactionOriginalAmount: 'b9c1901302f01c6b85a992b34f627bef',
  PickWorkflowUpdatedAt: '856378435a75e14fe829a6ca1cf03770',
  PreviewAccount: '8bdd0ec393150ed012713bae268c4878',
  PreviewApproval: '907b1441d5a7f0b71833962960ae360a',
  PreviewApprovalAssignees: 'f3c7f71676dc577aa0e309c1a5dd5edc',
  PreviewApprovalDelegation: 'c54441df35b192a6248fcada4126982a',
  PreviewAttachment: 'bc94039530244d16cb72f5cf5f6dbf63',
  PreviewBankAccount: 'b46048c2c13183c42c24a16b48c25bff',
  PreviewCardAuthorisation: '27dc60c2848a5d858368d6d28584e3fb',
  PreviewCardSubscription: '41d34da96bacdef8a1f74708f4c283ae',
  PreviewCardSubscriptionLatestTransaction: '0548f404f5ea850617c77032f9b4f366',
  PreviewCategory: '9badfb1432acee2f20f4aedcc6f594d4',
  PreviewCategoryEdit: '0cda84ecc9aefea2e6a2d3d2f36778c3',
  PreviewCategorySet: 'f2e7eef8bc2c50130a7564bed6c45e26',
  PreviewCostCentre: 'ef28bcd5fc014d85775ca794513d4b3b',
  PreviewCostCentreEdit: '15673403fa79479f56004fab9dd22450',
  PreviewCustomField: '184574478571cc6e07525b5a1a24fdef',
  PreviewCustomFieldEdit: '123184e6a6c2b3ffd645818f5ce90c6e',
  PreviewDataFilter: '29667e0e8c1e756265626403f4d3ac9c',
  PreviewDataSource: 'aa13fc4a621b4f99ada92e4344ee5bfc',
  PreviewDataSourceItem: 'f1fc6ddc260e4fda6836f3f2341a5d0d',
  PreviewDataSourceItemEdit: 'ac6b6436f1b9041071d96160b5209848',
  PreviewDataSourceItems: 'c595ee7e6cf651470d88529fcd8c1d26',
  PreviewDataView: '4d714bb3b821cca696de7c3fec990cb9',
  PreviewDataViewField: '82e6cec244cb209f4d51fd85538dcca7',
  PreviewDocument: '3d1a063e1230451aa0833a8079dc6007',
  PreviewDocumentEdit: 'cb2deb1f0fc624222f9e886d5820ca06',
  PreviewDraftDocument: '6fb8937f198984139e325174957d7f32',
  PreviewFlowActionApproveBy: '99da439c7f13be6513001a76b3ea6ea9',
  PreviewFlowActionAutomaticApprove: '956bb15109d2897ac066fa9153048d3b',
  PreviewFlowActionAutomaticReject: 'e50b24f13b620c26f4979a8e671ed55d',
  PreviewFlowActionComment: '297a4e01878f25dff307ca1870ce1efb',
  PreviewFlowActionCreatePurchaseOrder: '8fca145897da9e6bdd4d5eb15e1539a8',
  PreviewFlowStepCondition: '5d30b82c1b2b50309076a7d4dc91151a',
  PreviewFlowStepInputFalseSteps: '206aced7649c8e8dd0558c1498485c50',
  PreviewFlowStepInputTrueSteps: 'ff9e7d90befffbf9c823b51853ce7189',
  PreviewInbox: '8cf4aea08fd2ce11f0fa7f1a76474695',
  PreviewIncomingEmail: '73b9c7dc7ad2039f8b2fc45ff48cc954',
  PreviewIndustryCode: '30f3af995373cf1073dfe3015f1cfb33',
  PreviewIndustryCodeChildren: 'f141fbdcb8adc01aa647fb62eacdfdee',
  PreviewIndustryCodeSuppliers: '863a70e351e1c1a136058de233932d1e',
  PreviewInvoicePayment: 'b0709fa8e807eb60a17224ae9222ff4f',
  PreviewIssuerIntegrationEdit: '468d73d2da4939729135b30bb22969d9',
  PreviewIssuerIntegrationRule: 'f1c4713e4f49a432071eb1a462726af1',
  PreviewIssuerIntegrationView: '76936b8e1d2ea549dd4d7876e066565e',
  PreviewLocation: 'dd006b6f3118215a99dae55c3e0e9cf0',
  PreviewMerchant: 'ba370a4523e02bb45ca2e27b2c404a0e',
  PreviewOrganisationUnitEdit: '034f85ac2c435c525e22eebb6fa62c91',
  PreviewOrganisationUnitUsers: 'd60c6696641e1f2e7b78bf3165d3743d',
  PreviewOrganisationUnitView: '0581cc69e2a6bf4daca0bcced194e5e3',
  PreviewPurchaseOrder: '470bf7e36fa5037c873bfe11672ed1c9',
  PreviewPurchaseRequestItem: '742b98f1be0428d433d039f6a46d74fe',
  PreviewReport: '018659b3310fa528afb9471cf22624c3',
  PreviewReportSchedule: '15ec36b06c54de9d7142520b2a7da574',
  PreviewSupplierInvoicePayment: '629a75aeb06a9a93b8e8bbf29660c88f',
  PreviewSupplierUser: '81938c60de74da8b14e8f1f24deb0f26',
  PreviewTransaction: 'cd3ef65d9b294f8debde08b7a3acca90',
  PreviewUser: '92114834aa0dda5fea2e0061346c0d57',
  PreviewUserApprovals: '8202403e9dd4d21269d394bedd3850e6',
  PreviewUserRequests: '6265d6d8711bab82744450f73b9dfabe',
  PreviewVirtualCard: '06298c5472270ec2fbea7103809ae79f',
  PreviewWorkflow: '90f6042fee3cfcd39d49b2eeb54d080f',
  SelectAddress: '09510cf23e397029cbac85596d3a105d',
  SelectApprovalFlows: '59ab635e98796fcbd9f5e4bb0832cc44',
  SelectApproverUsers: 'f8c95a4f82a5444d3ce286faee56a77f',
  SelectAssigneePaths: '9e756fb96c0bfb00e3647c35577ac982',
  SelectAvailableInvoicePayoutCurrencies: 'fc9566da552dbc01cdc3e333a542631e',
  SelectAvailablePurchaseRequestTypes: 'b11d41964d7ea9583a0a135ec4e1da36',
  SelectCategories: 'a52daf1367b2921f5d8faa94c71c9d79',
  SelectCategorySets: '0247cdce7b09fcbc67ca91e582b84634',
  SelectComparators: 'e5f180e45279d4ad1c8298e6ec03fba2',
  SelectCostCentres: 'a1034a097825e19cae0be3939d7f1678',
  SelectCostCentresWithRelation: 'e800b400d6aea331e40d3bcc42fa2fdf',
  SelectCountries: '547d1fca8a9b872937d0a8fc2d7a1956',
  SelectCurrencies: '8d4526398357332867b616f1f4f7f1b6',
  SelectCustomFields: 'd9cdd42187fdf2cc694773841ce63503',
  SelectDataSources: 'dadadf24df28466775847032d63b0da8',
  SelectDataViews: 'a765a973d260fdea710b60297ac16ed5',
  SelectFeatureToggles: '5cd71611d19581717a5c4dacecd3a495',
  SelectIndustryCodes: '5abb5bbf8aa210b3ca087fcf7fac7d17',
  SelectIssuerIntegrations: 'e565fd26e3134ab190b9a8979b9cbd83',
  SelectLocations: '1fd1fc886d9411b408eba74e02d5ce68',
  SelectOptions: 'cab951d6b6fd402ec0dc9c3a567b6304',
  SelectOrganisationUnits: 'e8d2b2c7d6da4ad9a8cdbd269bb5a103',
  SelectOrganisationUnitsWithRelation: 'c3edbcb5b28200d1ca123eb3e0c674ba',
  SelectReferenceAssociations: '005858b2ea7e91018ac3d36fed992a92',
  SelectReferencePaths: '1604ec161407de7c578b4c589ed87d98',
  SelectRequestersWithRelation: '9048c8af77bf60243a61cf4f05e9a030',
  SelectSuppliers: '543675040283f9e3db7594c6852e3b07',
  SelectSupportedTransactions: '256523bb25e9885548898ec7f91e58bb',
  SelectTimezones: 'b79796a22d6d8b95dd698de75c87461c',
  SelectUsers: 'c6aa9ed73402a1c9fa4f9e654613d29f',
  ShowApproval: '3b8181d6fb61a21f6c29cd052909c378',
  ShowCategorySet: 'ab9a86756ae3afb13344e9ab457d2751',
  ShowCustomField: 'b4a9eb1f054c2c82fc300b9e0ad3ed39',
  ShowCustomFieldValue: '3e84bcf6015f8225989fc83d7f4774b7',
  ShowDataSource: '4ddf3a6d2049a8514bed544676e656af',
  ShowDataView: '477acb27e89fe2e01f3104c4a1e055f3',
  ShowDraftRequestItem: '458627c87eafd71e3f66c813e54ea2e4',
  ShowPurchaseRequestItem: '629bf36c74fc7407b137e10bf4f1c026',
  ShowSubscription: '3caa1015c350bc023ad80ca6c5fcaf1e',
  ShowSubscriptionPurchaseOrder: 'c4a0293b08290c8848cfa85ce13b7761',
  ShowUser: '9cb7115ee7959c2a8e77c63e2f333581',
  ShowWorkflow: 'a9fcf8a3553099bcdad0409fde3b2f52',
  NotificationCreated: 'd45d15eeea35d167957972eeb26571c3',
};

/**
 * The client who synced these operations with the server
 * @return {String}
 * @private
 */
const _client = 'frontend';

const OperationStoreClient = {
  /**
   * Build a string for `params[:operationId]`
   * @param {String} operationName
   * @return {String} stored operation ID
   */
  getOperationId: function (operationName) {
    return _client + '/' + OperationStoreClient.getPersistedQueryAlias(operationName);
  },

  /**
   * Fetch a persisted alias from a local operation name
   * @param {String} operationName
   * @return {String} persisted alias
   */
  getPersistedQueryAlias: function (operationName) {
    const persistedAlias = _aliases[operationName];
    if (!persistedAlias) {
      throw new Error('Failed to find persisted alias for operation name: ' + operationName);
    } else {
      return persistedAlias;
    }
  },

  /**
   * Satisfy the Apollo Link API.
   * This link checks for an operation name, and if it's present,
   * sets the HTTP context to _not_ include the query,
   * and instead, include `extensions.operationId`.
   * (This is inspired by apollo-link-persisted-queries.)
   */
  apolloLink: function (operation, forward) {
    if (operation.operationName) {
      const operationId = OperationStoreClient.getOperationId(operation.operationName);
      operation.setContext({
        http: {
          includeQuery: false,
          includeExtensions: true,
        },
      });
      operation.extensions.operationId = operationId;
    }
    return forward(operation);
  },
  /**
   * Satisfy the Apollo middleware API.
   * Replace the query with an operationId
   */
  apolloMiddleware: {
    applyBatchMiddleware: function (options, next) {
      options.requests.forEach(function (req) {
        // Fetch the persisted alias for this operation
        req.operationId = OperationStoreClient.getOperationId(req.operationName);
        // Remove the now-unused query string
        delete req.query;
        return req;
      });
      // Continue the request
      next();
    },

    applyMiddleware: function (options, next) {
      const req = options.request;
      // Fetch the persisted alias for this operation
      req.operationId = OperationStoreClient.getOperationId(req.operationName);
      // Remove the now-unused query string
      delete req.query;
      // Continue the request
      next();
    },
  },
};

export default OperationStoreClient;
